<template>
  <v-container class="partial-width-container">
    <v-card>
        <v-card-title>
            <span class="text-h5 text-xl-h5 text-lg-h5 text-md-h5">
                {{ partyName }} Notes
            </span>
        </v-card-title>
        <v-card-text>
            <validation-observer 
                v-slot="{ invalid }"
                ref="observer"
            >
                <v-form>
                    <validation-provider 
                        v-slot="{ errors }"
                        name="Party notes"
                        rules="max:10000" 
                    >
                        <v-textarea
                            v-model="newPartyNotes"
                            label="Party Notes" 
                            :error-messages="errors"
                            rows="10"
                            outlined
                            required
                        ></v-textarea>
                    </validation-provider>
                    <v-btn 
                        v-on:click="updatePartyNotes()"
                        color="secondary"
                        :disabled="invalid"
                    >
                        Submit
                    </v-btn>
                </v-form>
            </validation-observer>
        </v-card-text>
    </v-card>
    <v-snackbar
        v-model="snackbarSuccess"
        timeout="5000"
        color="green"
        dark
    >
        {{ snackbarMessage }}
        <template v-slot:action="{ attrs }">
            <v-btn
                v-bind="attrs"
                v-on:click="snackbar = false"
                text
            >
                Close
            </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar
        v-model="snackbarError"
        timeout="5000"
        color="red"
        dark
    >
        {{ snackbarMessage }}
        <template v-slot:action="{ attrs }">
            <v-btn
                v-bind="attrs"
                v-on:click="snackbar = false"
                text
            >
                Close
            </v-btn>
        </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  export default {
    name: 'Notes',
    data() {
      return {
        newPartyNotes: '',
        invalid: true,
        snackbarSuccess: false,
        snackbarError: false,
        snackbarMessage: '',
      }
    },
    computed: {
        // party name
        partyName: function() {
            return this.$store.getters.partyName;
        },

      // party notes
      partyNotes: function() {
        return this.$store.getters.partyNotes;
      },
    },
    methods : {
      updatePartyNotes() {
        this.$store.dispatch('editPartyNotes', { 'notes': this.newPartyNotes }).then(() => {
            this.snackbarSuccess = true;
            this.snackbarMessage = 'Party Notes Updated Successfully!'
        }, (error) => {
            this.snackbarError = true;
            this.snackbarMessage = error;
        });
      }
    },
    created() {
      this.newPartyNotes = this.partyNotes
    }
  }
</script>
